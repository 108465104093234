import React, { Fragment } from 'react';
import { TemplateFinancingItem, TypeOfFinanceTemplate, templateFinancingItemIsDebt, templateFinancingItemIsEquity } from '../../../../../../types';
import { Option } from '../../../../types';
import { debtDrawdownsTypeOptions } from '../../../calculator/components/popups/finance/assets/constans';
import { CustomInput, Dropdown } from '../../../calculator/components/ui';
import { ReactComponent as CopyImage } from '../../images/copy.svg';
import { ReactComponent as RemoveImage } from '../../images/remove.svg';
import { FieldContainer, ItemButtons, Label, FieldHeader } from '../../style';

interface Props {
  item: TemplateFinancingItem;
  firstItem?: boolean;
  typeOfFinanceTemplateOptions: Option[];
  updateItem?: (items: Partial<TemplateFinancingItem>) => void;
  getDefaultValue?: (type: TypeOfFinanceTemplate, key: string) => string;
  getDefaultValueDropdown?: (type: TypeOfFinanceTemplate, key: string, options: Option[]) => Option | undefined | '';
  removeItem?: () => void;
  copyItem?: () => void;
}

export const Line = ({ item, firstItem, typeOfFinanceTemplateOptions, updateItem, getDefaultValue, getDefaultValueDropdown, removeItem, copyItem }: Props) => {
  return <Fragment>
    <div>
      <div>
        {!updateItem && <Label>{item.type}</Label>}
        {updateItem && <Dropdown
          width='150px'
          minWidth='150px'
          placeholder='Type of finance'
          selectedValue={typeOfFinanceTemplateOptions.find(({ value }) => item.type === value)}
          valueChanges={(value) => updateItem?.({ type: value })}
          options={typeOfFinanceTemplateOptions}
        />}
        {(templateFinancingItemIsDebt(item) || templateFinancingItemIsEquity(item)) && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Percentage</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='75px'
            inputValue={getDefaultValue(item.type, 'percentage')}
            isNumber
            useSeparator
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : 'Percentage'}
            maxWidth={getDefaultValue ? '75px' : '150px'}
            inputValue={item.percentage}
            onBlur={(value) => updateItem?.({ percentage: value !== undefined ? value : item.percentage })}
            readOnly={!updateItem}
            isNumber
            useSeparator
          />
        </FieldContainer>}
        {(templateFinancingItemIsDebt(item) || templateFinancingItemIsEquity(item)) && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Drawdowns</FieldHeader>}
          {getDefaultValueDropdown && <Dropdown
            width='150px'
            minWidth='150px'
            placeholder='Default'
            selectedValue={getDefaultValueDropdown(item.type, 'drawdowns', debtDrawdownsTypeOptions)}
            options={debtDrawdownsTypeOptions}
            readOnly
          />}
          <Dropdown
            width='150px'
            minWidth={getDefaultValue ? '150px' : '300px'}
            placeholder={getDefaultValue ? 'Set' : 'Drawdowns'}
            selectedValue={debtDrawdownsTypeOptions.find(({ value }) => item.drawdowns === value)}
            valueChanges={(value) => updateItem?.({ drawdowns: value })}
            options={debtDrawdownsTypeOptions}
            readOnly={!updateItem}
          />
        </FieldContainer>}
        {templateFinancingItemIsEquity(item) && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Profit share</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='75px'
            inputValue={getDefaultValue(item.type, 'profitShare')}
            isNumber
            useSeparator
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : 'Profit share'}
            maxWidth={getDefaultValue ? '75px' : '150px'}
            inputValue={item.profitShare}
            onBlur={(value) => updateItem?.({ profitShare: value !== undefined ? value : item.profitShare })}
            readOnly={!updateItem}
            isNumber
            useSeparator
          />
        </FieldContainer>}
        {templateFinancingItemIsDebt(item) && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Interest</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='75px'
            inputValue={getDefaultValue(item.type, 'interest')}
            isNumber
            useSeparator
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : 'Interest'}
            maxWidth={getDefaultValue ? '75px' : '150px'}
            inputValue={item.interest}
            onBlur={(value) => updateItem?.({ interest: value !== undefined ? value : item.interest })}
            readOnly={!updateItem}
            isNumber
            useSeparator
          />
        </FieldContainer>}
        {templateFinancingItemIsDebt(item) && <FieldContainer>
          {getDefaultValue && firstItem && <FieldHeader>Fee</FieldHeader>}
          {getDefaultValue && <CustomInput
            placeholder='Default'
            maxWidth='75px'
            inputValue={getDefaultValue(item.type, 'fee')}
            isNumber
            useSeparator
            readOnly
          />}
          <CustomInput
            placeholder={getDefaultValue ? 'Set' : 'Fee'}
            maxWidth={getDefaultValue ? '75px' : '150px'}
            inputValue={item.fee}
            onBlur={(value) => updateItem?.({ fee: value !== undefined ? value : item.fee })}
            readOnly={!updateItem}
            isNumber
            useSeparator
          />
        </FieldContainer>}
      </div>
      {(removeItem || copyItem) && <ItemButtons>
        <CopyImage onClick={copyItem} />
        <RemoveImage onClick={removeItem} />
      </ItemButtons>}
    </div>
  </Fragment>;
};
