import { UnitType } from './units';

export interface ComparablesItem {
  _id: string;
  displayAddress: string;
  propertyImages: {
    images: Array<{
      _id: string;
      url: string;
      srcUrl: string;
      caption: string
    }>;
    mainImageSrc: string;
    mainMapImageSrc: string;
  };
  bedrooms: number;
  price: {
    amount: number;
    currencyCode: string;
    displayPrices: Array<{
      _id: string;
      displayPrice: string;
      displayPriceQualifier: string;
    }>,
    frequency: string;
  };
  location: {
    latitude: number;
    longitude: number;
  };
  totalFloorArea: {
    sqf: number;
    sqm: number;
  };
  floorplans: Array<{
    caption: string;
    resizedFloorplanUrls: Array<{
      size296x197: string;
    }>;
    type: string;
    url: string;
    _id: string;
  }>;
  propertySubType: string;
  propertyTypeFullDescription: string;
  summary: string;
  firstVisibleDate: Date;
  distance: number;
  externalLink: string;
  groupData?: {
    color?: string;
    searchBy?: string;
  }
}

export const saleStatusList = ['sold', 'unsold', 'all'] as const;

export type SaleStatus = typeof saleStatusList[number];

export interface ComparablesFilter {
  type?: UnitType[];
  bedroom?: number[];
  area?: {
    value?: string;
    radius?: number | undefined;
  };
  price?: {
    min: number;
    max: number;
  };
  saleStatus?: SaleStatus;
}

export const dateOfSellMonths = ['3', '6', '12', '24', 'All period'] as const;

export type DateOfSellMonth = typeof dateOfSellMonths[number];
