import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firstUppercase } from '../../../../../../../shared';
import { PresetSection, Template, TemplateTab, templateTabs } from '../../../../../../../types';
import { Confirmation } from '../../../../../components';
import { Tab, Tabs } from '../../../../settings/style';
import { setPopupOpen } from '../../../../../redux/calculate/calculate';
import { RootState } from '../../../../../redux/store';
import { deleteTemplate, storeTemplate } from '../../../../../redux/template/template';
import { BaseSettingsTab, ConstructionTab, FinancingTab, PurchaseTab, SalesTab } from '../../../../settings/tabs';
import PopupWrapper from '../../sidebar/shared/components/popup-wrapper/popup-wrapper';
import { Button, Buttons, Content, Header, Popup } from './style';

interface Props {
  section?: PresetSection;
}

export const TemplatePopup = ({ section }: Props) => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.Auth.user);
  const templates = useSelector((state: RootState) => state.Template.templates);
  const presets = useSelector((state: RootState) => state.Template.presets);
  const { templateId, isPreset: isPresetParam } = useSelector((state: RootState) => state.calculate.openedPopup.popupData);
  const currentData = useSelector((state: RootState) => state.calculate.analysePage.editingData);
  const selectorSq_ft_Sq_m = useSelector((state: RootState) => state.calculate.analysePage.selectorSq_ft_Sq_m);

  const sizeUnit = useMemo(() => selectorSq_ft_Sq_m === 'Sq.ft' ? 'sqft' : 'sqm', [selectorSq_ft_Sq_m]);
  const sizeName = useMemo(() => currentData.country === 'SWE' ? 'BOA' : 'NIA', [currentData]);

  const items = useMemo(() => [...templates, ...presets], [presets, templates]);
  const selectedTemplate = useMemo(() => items.find((template) => template.id === templateId), [templateId, items]);
  const defaultTemplate = useMemo(() => selectedTemplate?.isDefault ? undefined : items.find(({ isDefault }) => isDefault), [selectedTemplate, items]);
  const isPreset = useMemo(() => !!selectedTemplate?.section || isPresetParam, [selectedTemplate, isPresetParam]);

  const type = useMemo(() => isPreset ? 'preset' : 'template', [isPreset]);

  const newTemplate = useMemo(() => {
    if (!defaultTemplate) {
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const { id, isDefault, ...defaultTemplateData } = defaultTemplate;

    return {
      section,
      ...defaultTemplateData,
      name: `New ${type}`,
    } as Template;
  }, [defaultTemplate, section, type]);

  const [currentTab, setCurrentTab] = useState<TemplateTab>(templateTabs[0]);
  const [template, setTemplate] = useState(!templateId ? newTemplate : items.find((item) => item.id === templateId) || newTemplate);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const tabs = useMemo(() => templateTabs.filter((name) => [
    'base settings',
    ...(template?.section ? [template?.section] : []),
  ].includes(name) || !isPreset), [isPreset, template?.section]);

  const submit = useCallback(() => {
    if (!template) {
      return;
    }

    const { id, purchase, financing, construction, sales, ...rest } = template;

    const data = {
      ...rest,
      ...((rest.section || 'construction') === 'construction' ? { construction: {
        ...construction,
        preConstruction: { ...construction }?.preConstruction?.filter((item) => !!item.costType) || [],
        construction: { ...construction }?.construction?.filter((item) => !!item.costType) || [],
        professionalFees: { ...construction }?.professionalFees?.filter((item) => !!item.costType) || [],
      } } : {}),
      ...((rest.section || 'purchase') === 'purchase' ? { purchase: purchase?.filter((item) => !!item.name) } : {}),
      ...((rest.section || 'financing') === 'financing' ? { financing } : {}),
      ...(!rest.section ? { sales: sales?.filter((item) => !!item.name) } : {}),
    };

    delete data.userId;

    dispatch(storeTemplate(data, id));
    dispatch(setPopupOpen(null));
  }, [template, dispatch]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Enter' ) {
        submit();
        e.preventDefault();
        e.stopPropagation();
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [submit]);

  return <PopupWrapper onOutssideClick={() => console.log()}>
    {showDeleteConfirmation && template && <Confirmation
      title={`Delete ${type}?`}
      text={`'${template.name}' will be deleted`}
      buttons={[{
        text: 'No, Cancel',
        variant: 'secondary',
        onClick: () => setShowDeleteConfirmation(false),
      }, {
        text: 'Yes, Delete',
        variant: 'primary',
        onClick: () => {
          dispatch(deleteTemplate(template.id, isPreset ? 'preset' : 'template'));
          setShowDeleteConfirmation(false);
          dispatch(setPopupOpen(null));
        },
      }]}
    />}
    <Popup>
      <Header>{`${firstUppercase(type)}`}</Header>
      <Content>
        <Tabs popup>
          {tabs.map((name, i) => <Tab key={i} active={currentTab === name} onClick={() => setCurrentTab(name)}>{firstUppercase(name)}</Tab>)}
        </Tabs>
        {template && <div>
          {['base settings', 'all'].includes(currentTab) && <BaseSettingsTab
            name={template.name}
            isDefault={template.isDefault}
            isShared={template.isShared}
            isPreset={!!template.section || isPreset}
            section={template.section}
            base={template.base}
            isAdmin={user?.role === 'admin'}
            setName={(name) => setTemplate({ ...template, name })}
            setSection={(section) => setTemplate({ ...template, section })}
            setIsShared={(isShared) => setTemplate({ ...template, isShared })}
            setBase={(base) => setTemplate({ ...template, base })}
          />}
          {['purchase', 'all'].includes(currentTab) && template.purchase && <PurchaseTab
            defaultItems={template.isDefault ? undefined : templates.find(({ isDefault }) => isDefault)?.purchase}
            items={template.purchase}
            setItems={(items) => setTemplate({ ...template, purchase: items })}
          />}
          {['financing', 'all'].includes(currentTab) && template.financing && <FinancingTab
            defaultItems={template.isDefault ? undefined : templates.find(({ isDefault }) => isDefault)?.financing}
            items={template.financing}
            setItems={(items) => setTemplate({ ...template, financing: items })}
            isSummary={currentTab === 'all'}
          />}
          {['construction', 'all'].includes(currentTab) && template.construction && <ConstructionTab
            defaultData={template.isDefault ? undefined : templates.find(({ isDefault }) => isDefault)?.construction}
            data={template.construction}
            sizeUnit={sizeUnit}
            sizeName={sizeName}
            setData={(data) => setTemplate({ ...template, construction: data })}
            isSummary={currentTab === 'all'}
          />}
          {['sales', 'all'].includes(currentTab) && template.sales && <SalesTab
            defaultItems={template.isDefault ? undefined : templates.find(({ isDefault }) => isDefault)?.sales}
            items={template.sales}
            setItems={(items) => setTemplate({ ...template, sales: items })}
          />}
        </div>}
      </Content>
      <Buttons>
        <div>
          {!template?.isDefault && template?.id && <Button variant='cancel' onClick={() => setShowDeleteConfirmation(true)}>Delete {firstUppercase(type)}</Button>}
        </div>
        <div>
          <Button variant='cancel' onClick={() => dispatch(setPopupOpen(null))}>Cancel</Button>
          <Button
            variant='submit'
            disabled={isPreset && !template?.section}
            onClick={submit}>{templateId ? 'Edit' : 'Add'} {firstUppercase(type)}</Button>
        </div>
      </Buttons>
    </Popup>
  </PopupWrapper>;
};
