import { LocalMarket } from "../../../../types";

export const defaultLocalMarket: LocalMarket = {
  narrative: '',
  hint: '',
  timeSeries: [],
  market: {
    quartile50: 0,
    quartile75: 0,
    quartile90: 0,
    total: 0,
  },
  distribution: {
  },
  marketMappingValues: {
    bbox: [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
    ],
    result: [
    ],
  },
};
