import styled from 'styled-components';
import theme from '../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:last-of-type {
    max-height: calc(100% - 56px);
    min-height: calc(100% - 56px);
    display: flex;
    gap: 26px;
    margin: 20px 40px 24px 0;
    flex: 1;
  }
`;

export const Menu = styled.div`
  min-width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const MenuItem = styled.div<{active: boolean}>`
  position: relative;

  padding: 17px 30px 17px 16px;

  font-family: 'Basis Grotesque Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  ${(p) => p.active ? `
    background: ${theme.palette.grayMediumLight};
    border-radius: 8px;
  ` : ''}

  > div {
    width: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span {
    position: absolute;
    right: 8px;
    width: 24px;
    height: 24px;
    top: 15px;
  }
`;

export const Panel = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 16px;
  margin-bottom: auto;
  border-radius: 16px;
  border: 1px solid ${theme.palette.grayBlue};

  display: flex;
  flex-direction: column;
  gap: 16px;

  > div:nth-of-type(2) {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const Tabs = styled.div<{popup?: boolean; margin?: string}>`
  padding: 5px;
  border-radius: 8px;

  display: flex;
  gap: 4px;
  background: ${(p) => p.popup ? theme.palette.grayBlue : theme.palette.grayBackground};

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const Tab = styled.div<{active: boolean}>`
  padding: 6px 24px;
  border-radius: 6px;

  font-family: 'Basis Grotesque Pro';
  font-size: 14px;
  font-weight: ${(p) => p.active ? 600 : 400};
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  ${(p) => p.active ? `
    background: #fff;
    box-shadow: 0px 4px 8px 0px #2B46561F;
  ` : ''}


  cursor: pointer;
  user-select: none;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 24px;
  border-radius: 8px;
  background: var(--color-white);
  border: 1px solid ${theme.palette.grayBorder};
`;

export const TabContainer = styled.div<{spaceBetween?: boolean, margin?: string}>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}

  > div {
    display: flex;
    align-items: center;
    gap: 16px;

    ${(p) => p.spaceBetween ? `
      justify-content: flex-start;

      > div {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    ` : ''}
  }
`;

export const Label = styled.div.withConfig({
  shouldForwardProp: (prop) => !['header', 'bold', 'margin', 'padding', 'width'].includes(prop as string),
})<{header?: boolean; bold?: boolean; margin?: string; padding?: string; width?: string}>`
  width: ${(p) => p.width || '150px'};
  min-width: ${(p) => p.width || '150px'};
  
  font-family: 'Basis Grotesque Pro';
  letter-spacing: 0em;
  text-align: left;
  font-weight: ${(p) => p.bold ? '600' : '400'};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(p) => !p.header ? `
    font-size: 14px;
    line-height: 18px;
  ` : `
    font-size: 16px;
    line-height: 22px;
  `}

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
  ${(p) => p.padding ? `padding: ${p.padding};` : ''}
`;

export const AddItem = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;

  ${(p) => p.disabled ? `
    opacity: 0.5;
    cursor: default;
  ` : `
    cursor: pointer;
  `}
`;

export const SectionContainer = styled.div<{isSummary?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(p) => !p.isSummary ? 'overflow: auto;' : ''}
`;

export const ItemButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > svg {
    cursor: pointer;
  }
`;

export const FieldContainer = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
`;

export const FieldHeader = styled.div`
  position: absolute;
  top: -34px;
  width: 100%;

  font-family: 'Basis Grotesque Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`;

export const CollapsibleHeader = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop as string),
})<{isOpen: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;

  margin: 0;
  margin-left: 10px;
  
  font-family: 'Basis Grotesque Pro';
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  user-select: none;

  cursor: pointer;

  > svg {
    transform: ${(p) => p.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
    transition: 0.2s;
  }
`;
