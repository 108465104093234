import React, { useCallback, useEffect } from 'react';
import uuid from 'react-uuid';
import { TemplatePurchaseItem } from '../../../../../../types';
import { ReactComponent as AddImage } from '../../images/add.svg';
import { AddItem, Label, TabContainer, TabContent } from '../../style';
import { Line } from './line';

const defaultPurchaseItem: TemplatePurchaseItem = {
  name: '',
  isPredefined: false,
};

interface Props {
  items: TemplatePurchaseItem[];
  defaultItems?: TemplatePurchaseItem[];
  setItems?: (items: TemplatePurchaseItem[]) => void;
}

export const PurchaseTab = ({ items, defaultItems, setItems }: Props) => {
  const updateItem = useCallback((item: TemplatePurchaseItem, i: number) => {
    setItems?.([
      ...items.slice(0, i),
      item,
      ...items.slice(i + 1),
    ]);
  }, [items, setItems]);

  const copyItem = useCallback((i: number) => {
    setItems?.([...items.slice(0, i), items[i], ...items.slice(i)]);
  }, [items, setItems]);

  const removeItem = useCallback((i: number) => {
    setItems?.([...items.slice(0, i), ...items.slice(i + 1)]);
  }, [items, setItems]);

  return <TabContent>
    <div>
      <Label header bold margin='0 0 15px 0'>Purchase</Label>
      <TabContainer>
        {items.map((item, i) => <Line
          key={uuid()}
          item={item}
          updateItem={setItems ? (value) => updateItem({ ...item, ...value }, i) : undefined}
          copyItem={setItems ? () => copyItem(i) : undefined}
          removeItem={setItems ? () => removeItem(i) : undefined}
          defaultItems={defaultItems}
        />)}
        {items.length === 0 && <Label>No costs</Label>}
        {setItems && <AddItem onClick={() => setItems([...items, defaultPurchaseItem])}>
          <AddImage />
          <span>Add other fee</span>
        </AddItem>}
      </TabContainer>
    </div>
  </TabContent>;
};
