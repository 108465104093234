import React, { useState } from 'react';
import { TemplatePurchaseItem } from '../../../../../../types';
import { CustomInput } from '../../../calculator/components/ui';
import { ReactComponent as CopyImage } from '../../images/copy.svg';
import { ReactComponent as RemoveImage } from '../../images/remove.svg';
import { FieldContainer, FieldHeader, Label } from '../../style';

interface Props {
  item: TemplatePurchaseItem;
  firstItem?: boolean;
  defaultItems?: TemplatePurchaseItem[];
  updateItem?: (item: Partial<TemplatePurchaseItem>) => void;
  removeItem?: () => void;
  copyItem?: () => void;
}

export const Line = ({ item, firstItem, defaultItems, updateItem, removeItem, copyItem }: Props) => {
  const [name, setName] = useState(item.name);

  return <div>
    {!updateItem && <Label>{item.name}</Label>}
    {updateItem && <CustomInput
      placeholder='Cost name'
      maxWidth='200px'
      inputValue={name}
      valueChanges={(value) => setName(value)}
      onBlur={() => updateItem?.({ name })}
    />}
    <FieldContainer>
      {defaultItems && firstItem && <FieldHeader>Cost amount</FieldHeader>}
      {defaultItems && <CustomInput
        placeholder='Default'
        maxWidth='240px'
        inputValue={defaultItems.find((defaultItem) => item.name === defaultItem.name)?.totalCost || 0}
        readOnly
        isNumber
        useSeparator
      />}
      <CustomInput
        placeholder={defaultItems ? 'Set' : 'Cost amount'}
        maxWidth='240px'
        inputValue={item.totalCost}
        readOnly={!updateItem}
        // valueChanges={(value) => updateItem?.({ totalCost: value })}
        onBlur={(value) => updateItem?.({ totalCost: value !== undefined ? value : item.totalCost })}
        isNumber
        useSeparator
      />
    </FieldContainer>
    <FieldContainer>
      {defaultItems && firstItem && <FieldHeader>% of total site cost</FieldHeader>}
      {defaultItems && <CustomInput
        placeholder='Default'
        maxWidth='240px'
        inputValue={defaultItems.find((defaultItem) => item.name === defaultItem.name)?.percentPurchasePrice || 0}
        readOnly
        isNumber
        useSeparator
      />}
      <CustomInput
        placeholder={defaultItems ? 'Set' : '% of total site cost'}
        maxWidth='240px'
        inputValue={item.percentPurchasePrice}
        readOnly={!updateItem}
        // valueChanges={(value) => updateItem?.({ percentPurchasePrice: value })}
        onBlur={(value) => updateItem?.({ percentPurchasePrice: value !== undefined ? value : item.percentPurchasePrice })}
        isNumber
        useSeparator
      />
    </FieldContainer>
    {copyItem && removeItem && <>
      <CopyImage onClick={() => copyItem()} />
      <RemoveImage onClick={() => removeItem()} />
    </>}
  </div>;
};
