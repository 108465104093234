import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import React, { ChangeEvent, createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unitTypes } from '../../../../../../../../../../types';
import { Switcher } from '../../../../../../../../components';
import { getComparablesListAction, setPopupOpen } from '../../../../../../../../redux/calculate/calculate';
import { RootState } from '../../../../../../../../redux/store';
import { CustomInput } from '../../../../../ui/input/custom-input';
import PopupWrapper from '../../../../shared/components/popup-wrapper/PopupWrapper';
import CloseButton from '../../../../shared/elements/close-button/close-button';
import { InfoIcon } from '../../../../shared/elements/info-icon/info-icon';
import { Content, Double, Footer, Header, ItemContent, PopupContent } from './style';
import { useLocale } from '../../../../../../../../shared';

const bedroomTypes = [0, 1, 2, 3, 4, 5, 6];

export default function ComparablesFilter() {
  const { t } = useTranslation();

  const { currencySign } = useLocale({ defaultCurrencyValue: '' });

  const dispatch = useDispatch();

  const minPrice = 0;
  const maxPrice: number = Number.parseInt(t('comparables-max-price'));

  const defaultFormState = {
    type: [],
    bedroom: [],
    area: {
      value: 'default',
      radius: undefined,
    },
    price: {
      min: minPrice,
      max: maxPrice,
    },
  };

  const scrollableBlock = createRef<HTMLDivElement>();

  const comparablesFilterState = useSelector((state: RootState) => state.calculate.analysePage.comparablesService.comparablesFilter);
  const config = useSelector((state: RootState) => state.App.config);

  const fixedState = { ...comparablesFilterState };

  const [isAreaRadius, setIsAreaRadius] = useState(false);
  const [radiusRequired, setRadiusRequired] = useState(false);
  const [filterValues, setFilterValues] = useState(fixedState);
  const [priceValues, setPriceValues] = useState([minPrice, maxPrice]);

  useEffect(() => {
    if (comparablesFilterState) {
      const filterState = { ...comparablesFilterState };
      if (filterState.price)
        setPriceValues([filterState.price.min, filterState.price.max]);
      setFilterValues({ ...filterState });
    }
  }, [comparablesFilterState, setFilterValues]);

  useEffect(() => {
    if (filterValues?.area?.value === 'radius') {
      setIsAreaRadius(true);
      if (scrollableBlock?.current) {
        scrollableBlock.current.scroll({
          top: scrollableBlock.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    } else setIsAreaRadius(false);
  }, [filterValues, scrollableBlock]);

  const handlePriceSlider = (event: Event, newValue: number | number[]) => {
    setPriceValues(newValue as number[]);
  };

  const setRadius = (value: number) => {
    const clonedValues = { ...filterValues };
    Object.assign(clonedValues, {
      area: {
        value: filterValues?.area?.value,
        radius: value,
      },
    });
    setFilterValues(clonedValues);
    if (value) setRadiusRequired(false);
    else setRadiusRequired(true);
  };

  const clearFilters = () => {
    setPriceValues([minPrice, maxPrice]);
    setFilterValues(defaultFormState);
  };

  const cancelFilters = () => {
    dispatch(setPopupOpen(null));
  };

  const saveFilters = () => {
    if (isAreaRadius && !filterValues?.area?.radius) {
      setRadiusRequired(true);
    } else {
      const types = (filterValues.type?.length || 0) > 0 ? filterValues.type : undefined;
      const beds = (filterValues.bedroom?.length || 0) > 0 ? filterValues.bedroom : undefined;
      const minPrice = priceValues[0];
      const maxPrice = priceValues[1];
      const saleStatus = filterValues.saleStatus;

      dispatch(getComparablesListAction({ types, beds, minPrice, maxPrice, saleStatus }));
      dispatch(setPopupOpen(null));
    }
  };

  const formChanged = useCallback((eventTarget: EventTarget & HTMLInputElement) => {
    if (eventTarget.type === 'radio') {
      setFilterValues({
        ...filterValues,
        ...{ [`${eventTarget.name}`]: { value: eventTarget.value } },
      });
    } else if (eventTarget.type === 'checkbox') {
      if (!eventTarget.checked) {
        const value = eventTarget.name === 'bedroom' ? Number.parseInt(eventTarget?.value) : eventTarget?.value;
        const removeProperties = filterValues[`${eventTarget.name}`].filter((item) => item !== value);
        const clonedValues = filterValues;
        clonedValues[`${eventTarget.name}`] = removeProperties;
        setFilterValues({ ...clonedValues });
      } else {
        const value = eventTarget.name === 'bedroom' ? Number.parseInt(eventTarget?.value) : eventTarget?.value;
        setFilterValues({
          ...filterValues,
          [`${eventTarget.name}`]: [
            ...filterValues[`${eventTarget.name}`],
            value,
          ],
        });
      }
    }
  }, [filterValues]);

  const setSaleStatus = useCallback(() => {
    if ((filterValues.saleStatus || 'all') === 'all') {
      setFilterValues({
        ...filterValues,
        saleStatus: 'sold',
      });
    } else if (filterValues.saleStatus === 'sold') {
      setFilterValues({
        ...filterValues,
        saleStatus: 'unsold',
      });
    } else {
      setFilterValues({
        ...filterValues,
        saleStatus: 'all',
      });
    }
  }, [filterValues]);

  return (
    <PopupWrapper maxWidth='550px' onOutssideClick={() => dispatch(setPopupOpen(null))}>
      <PopupContent>
        <Header>
          <span>Filter</span>
          <CloseButton closeClicked={() => dispatch(setPopupOpen(null))} />
        </Header>
        <Content ref={scrollableBlock}>
          <Switcher
            title='Sold status'
            items={['All', 'Sold', 'Unsold']}
            active={filterValues.saleStatus === 'sold' ? 1 : (filterValues.saleStatus === 'unsold' ? 2 : 0)}
            width={243}
            setActive={() => setSaleStatus()}
            margin='0 0 15px 0'
          />
          <Double>
            <div>
              <div>
                <span>Property type</span>
                <InfoIcon text='Additional info' />
              </div>
              <ItemContent>
                {unitTypes.filter((type) => type !== 'Unspecified').map((type, i) => <div key={i}>
                  <input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => formChanged(event.target)}
                    checked={filterValues.type?.includes(type)}
                    type='checkbox'
                    value={type}
                    name='type'
                  />
                  <span>{type}</span>
                </div>)}
              </ItemContent>
            </div>
            <div>
              <div>
                <span>Bedroom number</span>
                <InfoIcon text='Additional info' />
              </div>
              <ItemContent wrapped>
                {bedroomTypes.map((type, i) => <div key={i}>
                  <input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => formChanged(event.target)}
                    checked={filterValues.bedroom?.includes(type)}
                    type='checkbox'
                    value={type}
                    name='bedroom'
                  />
                  <span>{type === 6 ? '6+' : type}</span>
                </div>)}
              </ItemContent>
            </div>
          </Double>
          <Double noMargin>
            <div>
              <div>
                <span>Area</span>
                <InfoIcon text='Additional info' />
              </div>
              <ItemContent>
                <div>
                  <input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => formChanged(event.target)}
                    checked={filterValues.area?.value?.includes('default')}
                    type='radio'
                    value='default'
                    name='area'
                  />
                  <span>Use default</span>
                </div>
                {config?.featureFlags.comparables.noRadiusFilter && <div>
                  <input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => formChanged(event.target)}
                    checked={filterValues.area?.value?.includes('radius')}
                    type='radio'
                    value='radius'
                    name='area'
                  />
                  <span>Use radius</span>
                </div>}
              </ItemContent>
              {isAreaRadius && <CustomInput
                inputValue={filterValues?.area?.radius}
                required={radiusRequired}
                placeholder='Radius, miles'
                maxWidth='100%'
                color='var(--color-whiteish-1)'
                valueChanges={(value) => setRadius(Number.isNaN(Number(value)) ? 0 : Number(value))}
              />}
            </div>
            <div className='item overflow'>
              <div>
                <span>Price/{currencySign}</span>
                <InfoIcon text='Price info' />
              </div>
              <ItemContent centered>
                <div>
                  <CustomInput
                    isNumber
                    isCurrency
                    inputValue={priceValues[0]}
                    placeholder='Minimum price'
                    maxWidth='100%'
                    color='var(--color-whiteish-1)'
                    valueChanges={(value) => setPriceValues([+value, priceValues[1]])}
                  />
                  <CustomInput
                    isNumber
                    isCurrency
                    inputValue={priceValues[1]}
                    placeholder='Maximum price'
                    maxWidth='100%'
                    color='var(--color-whiteish-1)'
                    valueChanges={(value) => setPriceValues([priceValues[0], +value])}
                  />
                </div>
                <Box sx={{ width: '90%' }}>
                  <Slider
                    step={10000}
                    min={minPrice}
                    max={maxPrice}
                    getAriaLabel={() => 'Price range'}
                    value={priceValues}
                    onChange={handlePriceSlider}
                    valueLabelDisplay='auto'
                  />
                </Box>
              </ItemContent>
            </div>
          </Double>
        </Content>
        <Footer>
          <div onClick={() => clearFilters()}>Clear</div>
          <div>
            <button type='button' onClick={() => cancelFilters()} className='primary-outline'>Cancel</button>
            <button type='button' onClick={() => saveFilters()} className='primary-field'>Save</button>
          </div>
        </Footer>
      </PopupContent>
    </PopupWrapper>
  );
}
